<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-40 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Jane's Story
      </div>

      <div class="py-12 flex flex-wrap gap-y-4 gap-x-12 justify-between text-blue-900">
        <div>
          <div class="font-bold text-lg">Location</div>
          <div class="text-lg">Missouri</div>
        </div>

        <div>
          <div class="font-bold text-lg">Situation</div>
          <div class="text-lg">30 year homeowner</div>
        </div>

        <div>
          <div class="font-bold text-lg">Job</div>
          <div class="text-lg">Roof repair & New roof</div>
        </div>

        <div>
          <div class="font-bold text-lg">Insurance Claim with Homeplan</div>
          <div class="text-lg">Claim raised from $12k to $53k</div>
        </div>

        <div>
          <div class="font-bold text-lg">Status</div>
          <div class="text-lg">Before and After</div>
        </div>
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 pt-4 pb-8 gap-4">
        <div class="md:col-span-2 md:row-span-2 relative">
          <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/j/before/jane 1.jpg" alt="">
          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">Before</div>
        </div>
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 23.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 22.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 5.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 3.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 77.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 78.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 79.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/before/jane 80.jpg" alt="">
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 py-4 gap-4">
        <div class="md:col-span-2 md:row-span-2 relative">
          <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/j/after/jane after 1.jpg" alt="">
          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">After</div>
        </div>
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 1.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 7.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 2.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 4.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 6.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 3.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/j/after/jane after 5.jpg" alt="">
      </div>

      <div class="mt-12 mb-40 text-lg text-blue-900">
        <span class="text-4xl font-bold text-blue-900 mr-2">Jane</span>asked Bash for help initially with replacing a front door handle and lock as she was about to embark upon a vacation. 
        He replaced it just in time for her to jet off into the sun. When she returned she informed Bash that she'd filed an insurance claim for hail damage as her roof began to leak and there was a hail storm. An adjuster had come out and she'd received a check from her insurance company and was about to deposit it.
      
        <br /><br />

        Bash informed her not to deposit the check and to allow him to get on her roof to survey the extent of the damage. 
        Upon climbing the ladder he immediately recognized that she in fact needed a total roof rip-off and replacement and that it wasn't going to be cheap. 
        After all, her roof was slate with copper flashing.
        Jane next asked Bash to handle her roof claim on her behalf.

        <br /><br />

        Bash hired a respected roofing company and they immediately got to work submitting supplements to the insurance company. 
        After 5 months and contacting a supervisor at the insurance company, the claim was paid out for $53k. 
        Jane received a brand new roof, copper flashing, new window wraps, house wrap, siding and plaster repair and paint. 
        The initial claim was a paltry $12k.

      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>